/* eslint-disable */
var icon = require('vue-svgicon');
icon.register({
    motherboard: {
        width: 16,
        height: 16,
        viewBox: '0 0 16 16',
        data: '<path pid="0" d="M11.5 2a.5.5 0 01.5.5v7a.5.5 0 01-1 0v-7a.5.5 0 01.5-.5m2 0a.5.5 0 01.5.5v7a.5.5 0 01-1 0v-7a.5.5 0 01.5-.5m-10 8a.5.5 0 000 1h6a.5.5 0 000-1zm0 2a.5.5 0 000 1h6a.5.5 0 000-1zM5 3a1 1 0 00-1 1h-.5a.5.5 0 000 1H4v1h-.5a.5.5 0 000 1H4a1 1 0 001 1v.5a.5.5 0 001 0V8h1v.5a.5.5 0 001 0V8a1 1 0 001-1h.5a.5.5 0 000-1H9V5h.5a.5.5 0 000-1H9a1 1 0 00-1-1v-.5a.5.5 0 00-1 0V3H6v-.5a.5.5 0 00-1 0zm0 1h3v3H5zm6.5 7a.5.5 0 00-.5.5v1a.5.5 0 00.5.5h2a.5.5 0 00.5-.5v-1a.5.5 0 00-.5-.5z"/><path pid="1" d="M1 2a2 2 0 012-2h11a2 2 0 012 2v11a2 2 0 01-2 2H3a2 2 0 01-2-2v-2H.5a.5.5 0 01-.5-.5v-1A.5.5 0 01.5 9H1V8H.5a.5.5 0 01-.5-.5v-1A.5.5 0 01.5 6H1V5H.5a.5.5 0 01-.5-.5v-2A.5.5 0 01.5 2zm1 11a1 1 0 001 1h11a1 1 0 001-1V2a1 1 0 00-1-1H3a1 1 0 00-1 1z"/>',
    },
});
